import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import AuthService from "../../../services/auth.service";
import GeneralService from "../../../services/general.service";
import ManagementService from "../../../services/management.service";
import UploadService from "../../../services/upload.service";
import nsPurchaseService from "../../../services/nsPurchase.service.js";
import purchaseRequirementsService from "../../../services/purchase/requirements.service";
import Filters from "../../../components/Filters/index.js";
import Content from "../../../components/Modal/General/content.js";
import VehicleReport from "../../../components/Modal/General/Pdf/VehicleReport.js";
import RejectContent from "../../../components/Modal/Reject/content";
import PrintForm from "../../../components/Modal/PrintForms/printForms.js";
import List from "../../../components/Table/List.js";
import ExcelButton from "../../../components/ExcelButton/index.js";
import ButtonIcon from "../../../components/ButtonIcon/index.js";
import {
  useFilters,
  useGrid,
  useStatusModal,
  useToast,
} from "../../../hooks/index";
import Modal from "../../../layouts/ModalDisplay.js";
import { diffDateBadge, SDPBadge, statusBadge } from "../../../utils/Badges.js";
import { downloadAllZip } from "../../../utils/File";
import {
  canceledStatus,
  purchaseStatus,
  serviceTypes,
} from "../../../variables/constants";
import { headSort } from "./constants/constants_columns";
import { filterFields } from "./constants/constants_filter";
import styles from "./styles.module.scss";

const finalStatus = [
  purchaseStatus.COMPLETED.text,
  purchaseStatus.REJECTED.text,
  purchaseStatus.DISMISSED_KAVAK.text
];

const next = {
  link: "/admin/rejected/list",
  text: "Rechazados",
};

const GeneralList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [dataModal, setDataModal] = useState(null);
  const [dataModalUpload, setDataModalUpload] = useState(null);
  const addToast = useToast();

  // Print Forms
  const [showPrinForm, setShowPrintForm] = useState(false);
  const [dataPrintForm, setDataPrintForm] = useState(null);

  const {
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
  } = useGrid({
    model: null,
    field: "vehicle_id",
    type: "ASC",
  });
  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);

  const {
    handleOpenAddModal,
    showAddModal,
    handleCloseAddModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showRejectModal,
    handleCloseEditModal,
    handleOpenEditModal,
    showEditModal,
  } = useStatusModal();

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("PANTALLA GENERAL");
    setCurrentPermissions(permissions);
  }, []);

  useEffect(() => {
    const downloadColumn = (data) => {
      const showIcons =
        currentPermissions &&
        currentPermissions.permissions.hasSelect &&
        data.imagenes > 0;
      return showIcons ? (
        <ButtonIcon
          onClick={(e) => handleDownload(data, e)}
          name="download"
          color="secondary"
          title="Descargar"
        />
      ) : null;
    };

    const sortColumns = (body) =>
      body.map((e) => ({
        vehicle_id: e.vehicle_id,
        plate_number: SDPBadge(e.plate_number, e.sdp),
        year_reception_date: e.year_reception_date,
        month_reception_date: e.month_reception_date,
        week_reception_date: e.week_reception_date,
        signing_form_08_date: e.signing_form_08_date,
        receive_form_08_date: e.receive_form_08_date,
        folio_in_progress_date: e.folio_in_progress_date,
        presentation_date: e.presentation_date,
        presentation_real_date: e.presentation_real_date,
        presentation_retired_real_date: e.presentation_retired_real_date,
        reception_cat_date: e.reception_cat_date,
        take_licence_date: e.take_licence_date,
        person_in_charge_id: e.person_in_charge_id,
        status: statusBadge(e.status),
        suspended_date: e.suspended_date,
        final_payment_date: e.final_payment_date,
        estimated_date: e.estimated_date,
        canceled_status_id: canceledStatus[e.canceled_status_id].text,
        aging: diffDateBadge(e.aging),
        diff_days: diffDateBadge(e.diff_days, e.target),
        observation: downloadColumn(e),
        _plate_number: e.plate_number,
        _certificate: e.certificate,
      }));

    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters: filters,
    };
    const emptyFilters = Object.keys(params.filters).every(
      (key) =>
        params.filters[key].value === "" ||
        params.filters[key].value === null ||
        params.filters[key].value.length === 0
    );
    setEmptyFilters(emptyFilters);
    if (emptyFilters) {
      handleTotalItems(0);
      handleTotalPage(0);
      handleBody(sortColumns([]));
      return;
    }

    GeneralService.getAllGeneral(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, filters]);

  const handleDownload = (data, e) => {
    e.preventDefault();
    const params = {
      countItems: 999,
      currentPage: 0,
      vehicleId: data.vehicle_id,
      plateNumber: data.plate_number,
      section: null,
    };
    UploadService.download(params).then((response) => {
      if (response.data.success) {
        downloadAllZip(response.data, data.plate_number);
      }
    });
  };

  // const handleSyncNS = (id, e) => {
  //   e.preventDefault();
  //   nsPurchaseService.sync(id).then((response) => {
  //     if (response.data.success) {
  //       addToast({
  //         variant: "info",
  //         header: "Toma - Pantalla General",
  //         body: "Se programó la sincronización a NetSuite de la toma correctamente",
  //       });
  //     } else {
  //       addToast({
  //         variant: "danger",
  //         header: "Toma - Pantalla General",
  //         body: "No se pudo programar la sincronización a NetSuite",
  //       });
  //     }
  //   });
  // };

  const handleShowModal = (id, e) => {
    e.preventDefault();
    GeneralService.getGeneralModalDataById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenAddModal();
      }
    });
  };

  const handleShowModalUpload = (data, e) => {
    e.preventDefault();
    setDataModalUpload(data);
    handleOpenEditModal();
  };

  const handleUpdate = (s) => {
    const dataVehicle = {
      ...s,
    };

    delete dataVehicle.requirements;

    const arrPromises = [];

    const promise_vehicle = new Promise((resolve, reject) => {
      ManagementService.edit(dataVehicle).then((response) => {
        if (response.success) {
          resolve("Se actualizaron los datos");
        }
        reject("Fallo la actualización de los datos");
      });
    });

    arrPromises.push(promise_vehicle);

    // Si estoy suspendiendo y cargo requisitos
    if (
      [
        purchaseStatus.PURCHASE_SUSPENDED.value,
        purchaseStatus.FOLIO_SUSPENDED.value,
        purchaseStatus.PRESENTATION_SUSPENDED.value,
      ].includes(s.purchase_file_status) &&
      s.requirements?.length
    ) {
      const promise_requirements = new Promise((resolve, reject) => {
        purchaseRequirementsService
          .insert(s.vehicle_id, s.requirements)
          .then((response) => {
            if (response.success) {
              resolve("Se crearon los requisitos");
            } else {
              reject("Fallo la creación de los requisitos");
            }
          });
      });

      arrPromises.push(promise_requirements);
    }

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "Toma - Pantalla General",
            body: v,
          })
        );
        refreshGrid();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "Toma - Pantalla General",
          body: reason,
        });
        refreshGrid();
      });
  };

  // Reject Modal
  const handleShowModalReject = (id, e) => {
    e.preventDefault();
    handleShowRejectModal(id);
  };

  const handleDelete = (params) => {
    ManagementService.edit({
      ...params,
      purchase_file_status: purchaseStatus.REJECTED.value,
    }).then((response) => {
      if (response.success) {
        addToast({
          variant: "success",
          header: "Toma - Pantalla General",
          body: "Se rechazó el tramite correctamente",
        });
        refreshGrid();
      }
    });
  };

  // Print modal
  const handleShowModalPrintForm = (id, e) => {
    e.preventDefault();
    setDataPrintForm({ id });
    setShowPrintForm(true);
  };

  const handleCloseModalPrintForm = () => setShowPrintForm(false);

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect ? (
          <>
            <ButtonIcon
              onClick={(e) => handleShowModal(props.vehicle_id, e)}
              name="file-alt"
              title="Editar"
            />
            <ButtonIcon
              onClick={(e) =>
                handleShowModalUpload(
                  { id: props.vehicle_id, plate_number: props._plate_number },
                  e
                )
              }
              name="upload"
              color="danger"
              title="Subir"
            />
            {props._certificate ? (
              <ButtonIcon
                onClick={(e) => handleShowModalPrintForm(props.vehicle_id, e)}
                name="print"
                color="info"
                title="Imprimir"
              />
            ) : null}
          </>
        ) : null}
        {/* <ButtonIcon
          onClick={(e) => handleSyncNS(props.vehicle_id, e)}
          name="cloud-upload-alt"
          color="warning"
          title="Sync Netsuite"
        /> */}
        {currentPermissions &&
        currentPermissions.permissions.hasDelete &&
        !finalStatus.includes(props.status.props.children) ? (
          <ButtonIcon
            onClick={(e) => handleShowModalReject(props.vehicle_id, e)}
            name="times"
            color="danger"
            size="lg"
            title="Rechazar"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={styles.generalExport}>
        <ExcelButton type="comprasGeneral" filters={filters} />
      </div>
      <Container fluid>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          next={next}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
          emptyFilters={emptyFilters}
        />
        {showAddModal && (
          <Modal
            content={
              <Content
                data={dataModal}
                handleUpdate={handleUpdate}
                handleClose={handleCloseAddModal}
              />
            }
            showModal={showAddModal}
            handleShow={handleShowModal}
            handleClose={handleCloseAddModal}
          />
        )}
        {showEditModal && (
          <Modal
            content={
              <VehicleReport
                data={dataModalUpload}
                handleClose={handleCloseEditModal}
                refreshGrid={refreshGrid}
              />
            }
            showModal={showEditModal}
            handleShow={handleShowModal}
            handleClose={handleCloseEditModal}
            size="xl"
          />
        )}
        {showRejectModal.show && (
          <Modal
            content={
              <RejectContent
                data={showRejectModal}
                handleDelete={handleDelete}
                handleClose={handleCloseRejectModal}
              />
            }
            showModal={showRejectModal.show}
            handleShow={handleShowModalReject}
            handleClose={handleCloseRejectModal}
          />
        )}
        {showPrinForm && (
          <Modal
            content={
              <PrintForm
                data={dataPrintForm}
                handleClose={handleCloseModalPrintForm}
                functionId={81}
              />
            }
            showModal={showPrinForm}
            handleShow={handleShowModalPrintForm}
            handleClose={handleCloseModalPrintForm}
            headerTitle="Impresión de formularios"
          />
        )}
      </Container>
    </div>
  );
};

export default GeneralList;
