export const pdfType = {
  "Informe de Estado de Dominio e Historico de Titularidad": "informe",
  "TÍTULO DEL AUTOMOTOR": "titulo",
};

export const sectionsNames = {
  vehicle: "DATOS DE DOMINIO",
  owner: "TITULAR",
  partner: "CONYUGE",
  license: "CEDULA",
  auth_license: "CEDULA AUTORIZADO",
  inhibidos: "INHIBIDOS",
  financed: "PRENDADO",
  historic_holder: "TITULARES HISTORICOS",
};

export const alias = {
  "de dominio": "Dominio:",
  "Certificado de Origen": "Nro. Certificado:",
  "CEDULA AUTORIZADO/ CEDULA ADICIONAL TERCEROS": "CEDULA AUTORIZADO",
};

export const multipartSections = {
  "Nro. Certificado:": {
    min: 4,
    sections: 2,
  },
};

export const connectedSections = {
  CONYUGE: "TITULAR",
};

// Secciones de multiples datos, tomamos un dato para separar los elementos,
// que seria el ultimo de esa "sección"
export const multiSectionArray = {
  "TITULARES HISTORICOS": {
    informe: {
      start: ["Titular Desde:", "Titular desde:"],
      end: ["Depto:", "Dpto.:"],
    },
    titulo: {
      start: ["Titular Desde:", "Titular desde:"],
      end: ["Depto:", "Dpto.:"],
    },
  },
  "CEDULA AUTORIZADO": {
    informe: {
      start: ["Nro Cedula:"],
      end: ["Nro.Doc:"],
    },
    titulo: {
      start: ["Nombre:"],
      end: ["Fecha de Emisión:", "Fecha de emisión:"],
    },
  },
};

export const uppercaseValues = ["Estado Civil:"];

export const extraFields = {
  "INFORMACION SUMINISTRADA POR EL REGISTRO SECCIONAL EMISOR Nro.":
    "issuer_entity_id",
};

export const extraDefinition = {
  issuer_entity_id: {
    section: "vehicle",
    label: "Registro de Radicación Nro.:",
    name: "issuer_entity_id",
  },
};

export const sections = {
  "TÍTULO DEL AUTOMOTOR": {
    key: "vehicle",
    "Dominio:": "plate_number",
    "Procedencia:": "origin",
    "Nro. Certificado:": "certificate_of_title_id",
    "Fecha Inscripción Inicial:": "original_enrollment",
    "Aduana:": "custom",
    "Código Automotor:": "automotive_code",
    "Marca:": "make",
    "Vto. Imp. Temp.:": "temp_import_expiration",
    "Tipo:": "type",
    "Modelo:": "model",
    "Mca.Motor:": "engine_make_id",
    "Nro.Motor:": "engine_identification_number",
    "Mca. Chasis:": "vehicle_make",
    "Nro. Chasis:": "vehicle_identification_number",
    "Fabricación Año:": "vehicle_manufacturing_year",
    "Modelo Año:": "model_year",
    "Fecha de Adquisición:": "purchase_date",
    "País de Fabricación:": "manufacturing_country",
    "Uso:": "vehicle_use",
    "País de Procedencia:": "country_of_origin",
    "Carrocería:": "body",
    "Condición:": "condition",
    "Cantidad de Placas:": "plate_amount",
    "Peso:": "vehicle_weight",
    "Número de Título:": "title_number",
    "Oblea RTO Nro.:": "rto_sticker_number",
    "VTO.:": "rto_sticker_expiration",
    "Régimen de Importación:": "import_regime",
  },
  "DATOS DE DOMINIO": {
    key: "vehicle",
    "DOMINIO:": "plate_number",
    "Procedencia:": "origin",
    "NroCertificado:": "certificate_of_title_id",
    "Fecha Inscripción Inicial:": "original_enrollment",
    "Aduana:": "custom",
    "Código Automotor:": "automotive_code",
    "Marca:": "make",
    "Vto.Imp Temp:": "temp_import_expiration",
    "Tipo:": "type",
    "Modelo:": "model",
    "Mca Motor:": "engine_make_id",
    "Nro.Motor:": "engine_identification_number",
    "Mca Chasis:": "vehicle_make",
    "Nro Chasis:": "vehicle_identification_number",
    "Fabricación Año:": "vehicle_manufacturing_year",
    "Modelo Año:": "model_year",
    "Fecha de Adquisición:": "purchase_date",
    "País de Fabricación:": "manufacturing_country",
    "Uso:": "vehicle_use",
    "País de Procedencia:": "country_of_origin",
    "Carroceria:": "body",
    "Condicion:": "condition",
    "Cantidad de Placas:": "plate_amount",
    "Peso:": "vehicle_weight",
    "Número de Titulo:": "title_number",
    "Oblea RTO Nro:": "rto_sticker_number",
    "Regimen de Importación:": "import_regime",
  },
  TITULAR: {
    key: "owner",
    "Nombre:": "holder_name",
    "Razón Social:": "holder_name",
    "Porcentaje de Titular:": "holder_percentage",
    "Cuit:": "holder_cuit",
    "Cuil:": "holder_cuit",
    "TipoDoc:": "document_type",
    "Tipo Doc.:": "document_type",
    "NroDoc:": "document_number",
    "Nro. Doc.:": "document_number",
    "FechaNacimiento:": "birthday",
    "Fecha Nacimiento:": "birthday",
    "Estado Civil:": "marital_status",
    "Nacionalidad:": "nationality",
    "Provincia:": "province",
    "Partido:": "department",
    "Localidad :": "locality",
    "Localidad:": "locality",
    "Codigo Postal:": "zip_code",
    "Código Postal:": "zip_code",
    "Barrio:": "neighborhood",
    "Calle:": "street",
    "Nro:": "number",
    "Nro.:": "number",
    "Piso:": "floor",
    "Depto:": "apartment",
    "Dpto.:": "apartment",
    "Caracter del Bien:": "economic_good",
    "Titular Desde:": "holder_from",
    "Adquisición:": "adquisition_type",
    "Estipulacion Terceros:": "third_party_stipulation",
    "Estipulación Terceros:": "third_party_stipulation",
    "Titular de Radicación:": "registration_number",
  },
  CONYUGE: {
    key: "partner",
    "Nombre:": "holder_name",
    "Tipo Doc:": "document_type",
    "Tipo Doc.:": "document_type",
    "Nro Doc:": "document_number",
    "Nro. Doc.:": "document_number",
    "Caracter del Bien:": "economic_good",
    "Titular Desde:": "holder_from",
    "Titular desde:": "holder_from",
    "Adquisición:": "adquisition_type",
    "Estipulacion Terceros:": "third_party_stipulation",
    "Titular de Radicación:": "registration_number",
    "Estipulación Terceros:": "third_party_stipulation",
  },
  CEDULA: {
    key: "license",
    "Nro Cedula:": "sticker_number",
    "Número de cédula:": "sticker_number",
    "Fecha de Emisión:": "issue_date",
    "Fecha de emisión:": "issue_date",
    "Fecha de Vencimiento:": "expiration_date",
    "Fecha de vencimiento:": "expiration_date",
  },
  "CEDULA AUTORIZADO": {
    key: "auth_license",
    "Nro Cedula:": "sticker_number",
    "Número de cédula:": "sticker_number",
    "Fecha de Emisión:": "issue_date",
    "Fecha de emisión:": "issue_date",
    "Nombre:": "holder_name",
    "Tipo Doc:": "document_type",
    "Tipo Doc.:": "document_type",
    "Nro.Doc:": "document_number",
    "Nro. Doc.:": "document_number",
  },
  // INHIBIDOS: {
  //   key: "inhibidos",
  // },
  // PRENDADO: {
  //   key: "financed",
  //   "Acreedor Prendario:": "financed_provider",
  //   "NroCPD:": "",
  // },
  "TITULARES HISTORICOS": {
    key: "historic_holder",
    "Nombre:": "owner_name",
    "TipoDoc:": "document_type",
    "Tipo Doc:": "document_type",
    "Tipo Doc.:": "document_type",
    "NroDoc:": "document_number",
    "Nro Doc:": "document_number",
    "Nro. Doc.:": "document_number",
    "Razon Social:": "business_name",
    "Titular Desde:": "owner_from",
    "Titular desde:": "owner_from",
    "Titular Hasta:": "owner_to",
    "Titular hasta:": "owner_to",
    "Cuit/Cuil:": "owner_cuit_cuil",
    "Porcentaje de Titular:": "ownership_percentage",
    "Titular de Radicación:": "registration_number",
    "Titular de Radicacion:": "registration_number",
    "NroIGJ:": "igj_number",
    "Sociedad en Formacion:": "society_in_formation",
    "Titular de Radicacion:": "filing_holder",
    "Provincia:": "administrative_area_level_1",
    "Partido:": "administrative_area_level_2",
    "Localidad :": "locality",
    "Localidad:": "locality",
    "Codigo Postal:": "zip_code",
    "Código Postal:": "zip_code",
    "Barrio:": "neighborhood",
    "Calle:": "street",
    "Nro:": "number",
    "Nro.:": "number",
    "Piso:": "floor",
    "Depto:": "apartment",
    "Dpto.:": "apartment",
  },
};
