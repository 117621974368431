import React from "react";
import { BadgeField } from "../components";
import { canceledStatus, sells_status } from "variables/constants";
import { diffDateFormula } from "utils/Badges";
import { useGetStatus } from "hooks";

const DomainAndStatus = ({ fields }) => {
  const { status } = useGetStatus(fields.status, sells_status);
  return (
    <>
      <div className="row">
        <BadgeField
          label={"Dominio"}
          text={fields.domain}
          badgeClass={"plate_number"}
          variant={"warning"}
        />
        <BadgeField
          label={"Stock ID"}
          text={fields.stock_id || "Pendiente"}
          badgeClass={`plate_number`}
          variant={fields.stock_id ? "info" : "danger"}
        />
        <BadgeField
          label={"Estado"}
          text={status.text}
          badgeClass={`badge ${status.color} capitalize`}
          style={{ width: "100%", padding: "13px" }}
        />
        {fields?.legalization_date ? (
          <BadgeField
            label={"Inicio Legalización"}
            text={fields?.legalization_date}
            badgeClass={`capitalize plate_number`}
            variant={"secondary"}
          />
        ) : null}
      </div>
      <div className="row">
        <BadgeField
          label={"Días en tramite"}
          text={fields.diff_days || 0}
          badgeClass={`capitalize plate_number`}
          variant={fields.diff_days ? diffDateFormula(fields.diff_days) : "danger"}
        />
        <BadgeField
          label={"Días Suspendidos"}
          text={fields.suspended_days}
          badgeClass={`capitalize plate_number`}
          variant={"info"}
        />
        <BadgeField
          label={"Pago de Sellado"}
          text={`${fields.avoidStampPayment ? "NO" : ""} PAGA SELLOS`}
          badgeClass={`capitalize plate_number`}
          variant={fields.avoidStampPayment ? "warning" : "danger"}
        />
        {fields.canceled_status_id ? (
          <BadgeField
            label={"Requiere Baja"}
            text={canceledStatus[fields.canceled_status_id].text}
            badgeClass={`plate_number`}
            variant={fields.canceled_status_id === 1 ? "success" : "danger"}
          />
        ) : null}
      </div>
    </>
  );
};

export default DomainAndStatus;
