import React, { useEffect, useState } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import Card from "../../../components/Card/Card.jsx";
import { Comments } from "../../../components/Comments";
import { purchaseStatus } from "../../../variables/constants";
// import { status08 } from "../../variables/constants";
import { /* status08Badge, */ statusBadge } from "../../../utils/Badges";
import { purchaseComment } from "../../../utils/Comments";

const TRANSACTION_TYPE_CONSIGNACION = 5;

const Information = (props) => {
  const [information, setInformation] = useState(false);

  useEffect(() => {
    setInformation(props.data);
  }, [props.data]);

  // Standard - Todo aquello que sea una Consignación. (El dato sale de la pantalla de toma)
  // Prep NDD - (Todos aquellos que no sean consignación y aun no recibimos las cedulas, es decir que están en preparación.)
  // NDD - (Todos aquellos que ya están terminados y hemos recibido la cedulas)
  const calculateStatus = (info) => {
    let text;
    if (!info) {
      return "-";
    }
    if (!info.transaction_type_id) {
      return "Tipo De Operación sin definir";
    }
    if (info.transaction_type_id === TRANSACTION_TYPE_CONSIGNACION) {
      text = "Standard";
    } else if (info.take_licence_date) {
      text = "NDD";
    } else {
      text = "Prep NDD";
    }
    return `${text}${
      info.diff_days
        ? ` (${info.diff_days} día${info.diff_days > 0 ? "s" : ""} hábil${
            info.diff_days > 0 ? "es" : ""
          })`
        : ""
    }`;
  };

  const renderRow = (label, data) => (
    <tr>
      <td>
        <strong>{label}</strong>
      </td>
      <td>{data ?? "-"}</td>
    </tr>
  );

  return (
    <div className="plate-information" style={{ width: "100%" }}>
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Información"
        content={
          props.loading ? (
            "Loading..."
          ) : (
            <>
              <Table striped hover>
                <tbody>
                  {renderRow("Id", information?.vehicle_id)}
                  {renderRow("Dominio", information?.plate_number)}
                  {renderRow("Estado", calculateStatus(information))}
                  {renderRow(
                    "Estado de la Toma",
                    information?.status ? statusBadge(information.status) : "-"
                  )}
                  {/* {renderRow("Estado de Cedulas", information?.take_licence_date
                      ? (<>
                        <span>{moment(information.take_licence_date).format('DD/MM/YYYY')}</span>
                        {status08Badge(status08.RECEIVED.value)}
                      </>)
                      : '-')} */}
                  {renderRow(
                    "Fecha Estimada Gestoria",
                    information?.estimated_date
                      ? moment(information.estimated_date).format("DD/MM/YYYY")
                      : "-"
                  )}
                  {renderRow("Registro de Radicación", information?.issuer_entity)}
                  {/* {renderRow("Fecha Recepción", information?.reception_date)
                      ? moment(information.reception_date).format('DD/MM/YYYY')
                      : '-')} */}
                </tbody>
              </Table>
              {information?.comments ? (
                <Comments
                  comments={purchaseComment(information?.comments)}
                  statusList={purchaseStatus}
                  hideReasons
                />
              ) : null}
            </>
          )
        }
      />
    </div>
  );
};

export default Information;
