import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import { sells_status, sells_status_select } from "variables/constants";
import { useConfirmationAlert, useToast } from "../../../../hooks/";
import FormService from "../../../../services/form.service";
import SellsService from "../../../../services/sells/sells.service";
import SellsFolioService from "../../../../services/sells/sellsFolio.service";
import { Comments } from "../../../Comments/index";
import { CarInfo, DataClient } from "../components/";
import { AddForms } from "./components/index";
import { DatePickerField } from "components/Form/index";
import { InputField, SelectField } from "../../../../components/Form";
import { sellEmployees } from "utils/Service";

const STATUS_FOLIO_STAND_BY = "FOLIO_IN_PROGRESS_STAND_BY";

const statusToChange = {
  STATUS_FOLIO_STAND_BY: 5,
  STATUS_FOLIO_OBSERVED: 19,
  PRESENTATION_WAITING: 6,
};

const STATUS_FOLIO_OBSERVED = "FOLIO_IN_PROGRESS_OBSERVED";
const STATUS_FOLIO_SUSPENDED = "FOLIO_IN_PROGRESS_SUSPENDED";

const Edit = (props) => {
  const {
    data: {
      response: {
        id,
        opportunity_id,
        plate_number,
        delivery_type,
        financed,
        status_ns,
        status,
        delivery_date,
        status_id,
        financing_provider,
        vehicle_price,
        auto_trade_in,
        owner,
        signature_place,
        bill_created_date,
        comments,
        estimated_delivery_date,
        restore_status,
        estimated_end_trial,
        diff_days,
        financed_date,
        stock_id,
        canceled_status_id,
        person_in_charge_id,
        suspended_date,
        suspended_days,
        bacs_id,
        report_vehicle_sale_date,
        financing_amount,
        transaction_type,
        legalization_date,
      },
      folio_forms,
    },
    handleClose,
    refresh,
  } = props;

  const initialFormState = {
    id,
    opportunity_id,
    domain: plate_number,
    delivery_type,
    prendado: financed,
    status_ns,
    status,
    delivery_date: delivery_date ? new Date(delivery_date) : null,
    payment_date: bill_created_date ? new Date(bill_created_date) : null,
    provider: financing_provider,
    price: vehicle_price,
    car_trade_in: auto_trade_in ? 1 : 0,
    finish_date_test: estimated_end_trial
      ? new Date(estimated_end_trial)
      : null,
    owner: owner != null ? owner : [],
    signature_place,
    observation: null,
    cancel_reason_id: null,
    folio_forms,
    estimated_delivery_date: estimated_delivery_date
      ? new Date(estimated_delivery_date)
      : null,
    diff_days,
    suspended_days,
    financed_date: financed_date !== null ? new Date(financed_date) : null,
    stock_id,
    canceled_status_id,
    person_in_charge_id,
    bacs_id,
    report_vehicle_sale_date,
    financing_amount,
    transaction_type,
    legalization_date,
  };

  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();
  const [forms, setForms] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [allowRemove, setAllowRemove] = useState([]);
  const addToast = useToast();

  useEffect(() => {
    getReasons();
    getForms();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setDisabledSubmit(
      !fields.delivery_date ||
        !fields.folio_forms.length ||
        fields.folio_forms.filter((f) => !f.has_folio_in_progress).length !==
          0 ||
        (fields.prendado && (!fields.financed_date || !fields.financing_amount))
      // ||
      // (fields.delivery_type !== "STD" &&
      //   +new Date(fields.finish_date_test) > +new Date() &&
      //   !suspended_date)
    );
  }, [fields, suspended_date]);

  const getReasons = async () => {
    try {
      const response = await SellsService.getAllReasons();
      if (response.status === 200) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getForms = () => {
    const params = {
      currentPage: 0,
      countItems: 1000,
      use_for_purchase: null,
      use_for_sell: 1,
    };
    FormService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        const formsData = response.data.response
          .filter(
            (f) =>
              initialFormState.folio_forms.findIndex(
                (i) => i.form_code === f.form_code
              ) === -1
          )
          .map((r) => ({ id: r.form_code, name: r.description }));
        setForms(formsData);
        const allow_remove = response.data.response
          .filter((f) => f.allow_remove && !f.initial_for_sell)
          .map((r) => r.form_code);
        setAllowRemove(allow_remove);
      }
    });
  };

  const submit = async (next_status_id) => {
    const data = {
      financed: fields.prendado,
      folio_forms: fields.folio_forms,
      financed_date: fields.prendado ? fields.financed_date : null,
      comment: fields.observation,
      comment_reason_id: fields.cancel_reason_id,
      status_id: next_status_id,
      person_in_charge_id: fields.person_in_charge_id,
      delivery_date: fields.delivery_date,
      financing_provider: fields.provider,
      financing_amount: fields.financing_amount,
    };

    try {
      const response = await SellsFolioService.updateForms(
        opportunity_id,
        data
      );
      if (response.status === 200) {
        addToast({
          variant: "success",
          header: "Ventas - Apertura Folio",
          body: "Se actualizó la información correctamente",
        });
        handleClose();
        refresh();
      }
    } catch (error) {
      throw error;
    }
  };

  const handleStatusStandBy = () => {
    let actual_status = statusToChange.STATUS_FOLIO_STAND_BY;
    if (status_id === statusToChange.STATUS_FOLIO_STAND_BY) {
      actual_status = restore_status;
    }
    const statusBefore = sells_status_select.filter(
      (x) => x.value === actual_status
    )[0];
    handleConfirmationModal(
      status !== STATUS_FOLIO_STAND_BY
        ? "Folio en Progreso - En Espera"
        : statusBefore.text,
      () => submit(actual_status)
    );
  };

  const handleStatusFolioObserved = () => {
    let actual_status = statusToChange.STATUS_FOLIO_OBSERVED;
    if (status_id === statusToChange.STATUS_FOLIO_OBSERVED) {
      actual_status = restore_status;
    }
    const statusBefore = sells_status_select.filter(
      (x) => x.value === actual_status
    )[0];

    handleConfirmationModal(
      status !== STATUS_FOLIO_OBSERVED
        ? "Folio en Progreso - Observado"
        : statusBefore.text,
      () => submit(actual_status)
    );
  };

  const changeStatusToFinished = () => {
    let count = 0;
    fields.folio_forms.forEach((x) => {
      if (x.has_folio_in_progress === true) {
        count++;
      }
    });
    if (count === fields.folio_forms.length) {
      handleConfirmationModal("Presentación - En Espera", () =>
        submit(statusToChange.PRESENTATION_WAITING)
      );
    } else {
      addToast({
        variant: "danger",
        header: "Ventas - Apertura Folio",
        body: "Hay formularios que no se encuentran recibidos",
      });
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <CarInfo
            fields={fields}
            disabledAll={true}
            diff_days={true}
            fieldsEnabled={["delivery_date"]}
            onSimpleFieldChange={onSimpleFieldChange}
          />
          <div className="row">
            <SelectField
              label={"Persona responsable"}
              name={"person_in_charge_id"}
              onChange={(e) =>
                onSimpleFieldChange(
                  "person_in_charge_id",
                  !!e.target.value ? parseInt(e.target.value, 10) : null
                )
              }
              value={fields.person_in_charge_id}
              options={sellEmployees()}
            />

            {fields.prendado.toString() === "true" ? (
              <>
                <DatePickerField
                  label={"Recepción Prenda"}
                  selected={fields.financed_date}
                  onChange={(date) =>
                    onSimpleFieldChange("financed_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <InputField
                  label={"Monto de financiación"}
                  value={fields.financing_amount}
                  onChange={(e) =>
                    onSimpleFieldChange("financing_amount", e.target.value)
                  }
                  name={"financing_amount"}
                  type="number"
                />
              </>
            ) : null}
          </div>
          <div className="row">
            {fields.bacs_id ? (
              <DatePickerField
                label={"Denuncia de venta"}
                selected={
                  fields.report_vehicle_sale_date
                    ? new Date(fields.report_vehicle_sale_date)
                    : null
                }
                disabled={true}
                dateFormat="dd/MM/yyyy"
              />
            ) : null}
          </div>
          <br />
          <DataClient owner={owner} />
          <AddForms
            forms={forms}
            fields={fields}
            onSimpleFieldChange={onSimpleFieldChange}
            setForms={setForms}
            allowRemove={allowRemove}
          />
          <br />
          <Comments
            reasons={reasons}
            comments={comments}
            statusList={sells_status}
            onChangeSelect={(e) =>
              onSimpleFieldChange("cancel_reason_id", e.target.value)
            }
            onChangeObs={(e) =>
              onSimpleFieldChange("observation", e.target.value)
            }
          />
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-success btn-sm"
              onClick={() => submit(status_id)}
            >
              Actualizar
            </Button>
          </div>
          {status !== STATUS_FOLIO_SUSPENDED && (
            <>
              <div>
                <Button
                  className="btn-fill pull-right btn btn-info btn-sm"
                  onClick={handleStatusStandBy}
                >
                  {status !== STATUS_FOLIO_STAND_BY
                    ? "Poner en Espera"
                    : "Eliminarlo de Espera"}
                </Button>
              </div>
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-warning btn-sm"
                  onClick={handleStatusFolioObserved}
                  disabled={
                    fields.observation == null ||
                    fields.cancel_reason_id == null
                      ? true
                      : false
                  }
                >
                  {status !== STATUS_FOLIO_OBSERVED
                    ? "Observar"
                    : "Desobservar"}
                </Button>
              </div>
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-primary btn-sm"
                  onClick={changeStatusToFinished}
                  disabled={disabledSubmit}
                >
                  Aceptar
                </Button>
              </div>
            </>
          )}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Edit;
