import React, { useState, useEffect, useContext, useRef } from "react";
import DatePicker from "react-datepicker";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Multiselect from "multiselect-react-dropdown";
import Dropdown from "../../../components/Table/Dropdown";
import { UserContext } from "../../../context/UserContext";
import ReceptionPlaceService from "../../../services/receptionPlace.service";
import CanceledStatusService from "../../../services/purchaseCanceledStatus.service";
import {
  status08Badge,
  plateNumberBadge,
  badgeGeneric,
  diffDateFormula,
} from "../../../utils/Badges";
import { issuerEntitiesAll, transactionTypes } from "../../../utils/Service";
import {
  constants,
  status08,
  purchaseStatus,
  yesNoOptions,
} from "../../../variables/constants";
import { BadgeField } from "../Sells/components";

const createdAtUpdate = [constants.adminProfile, constants.coordinatorProfile];

const Summary = (props) => {
  const {
    fields,
    fields: {
      createdAt,
      transaction_type_id,
      is_recived,
      // vehicle_price,
      // loss_request,
      final_payment_date,
      reception_place_id,
      form_08,
      canceled_status_id,
      pledge,
      issuer_entity_tentative_id,
      issuer_entity_tentative,
      issuer_entity_id,
      issuer_entity,
    },
    readOnlyfields: {
      plate_number,
      sdp,
      inspector,
      is_recived: read_only_is_recived,
      forms: { form_08: read_only_form_08 },
      purchase_file_status: read_only_purchase_file_status,
      diff_days,
      target,
    },
    onSimpleFieldChange,
    onComplexFieldChange,
    setFields,
  } = props;

  const userContext = useContext(UserContext);

  const [disabledByRol, setDisabledByRol] = useState(false);
  const [placesOptions, setPlacesOptions] = useState([]);
  const [canceledStatus, setCanceledStatus] = useState([]);
  const [profile, setProfile] = useState(null);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [selectedIssuerTentative, setSelectedIssuerTentative] = useState(null);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedIssuer([selectedItem]);
    onSimpleFieldChange("issuer_entity_id", selectedItem.value);
  };

  const onRemove = () => {
    onSimpleFieldChange("issuer_entity_id", null);
  };

  const onSelectTentative = (selectedList, selectedItem) => {
    setSelectedIssuerTentative([selectedItem]);
    onSimpleFieldChange("issuer_entity_tentative_id", selectedItem.value);
  };

  const onRemoveTentative = () => {
    onSimpleFieldChange("issuer_entity_tentative_id", null);
  };

  useEffect(() => {
    ReceptionPlaceService.getAll({ sortField: "name", sortType: "ASC" }).then(
      (response) => {
        if (response.data.count > 0) {
          setPlacesOptions(
            response.data.response.map((r) => ({ value: r.id, text: r.name }))
          );
        }
      }
    );
    CanceledStatusService.getAll({ sortField: "id", sortType: "ASC" }).then(
      (response) => {
        if (response.data.count > 0) {
          setCanceledStatus(response.data.response);
        }
      }
    );
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());
    if (issuer_entity_id != null) {
      setSelectedIssuer([
        {
          value: issuer_entity_id,
          text: issuer_entity,
        },
      ]);
    }
    if (issuer_entity_tentative_id != null) {
      setSelectedIssuerTentative([
        {
          value: issuer_entity_tentative_id,
          text: issuer_entity_tentative,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (!is_recived || (is_recived && userContext.isRolAdmin)) {
      setDisabledByRol(false);
    }
    if (is_recived && !userContext.isRolAdmin) {
      setDisabledByRol(true);
    }
  }, [is_recived, userContext]);

  return (
    <>
      <Form>
        <div className="container">
          <div className="row">
            <BadgeField
              label={constants.domain}
              text={plate_number}
              badgeClass={"plate_number"}
              variant={"warning"}
            />
            <BadgeField
              label={"Días en tramite"}
              text={diff_days ?? 0}
              badgeClass={`capitalize plate_number`}
              variant={diffDateFormula(diff_days, target)}
            />
            <div className="col">
              <label className="capitalize">
                <strong>{constants.transactionType}</strong>
              </label>
              <div>
                <Select
                  className="form-control"
                  name="transaction_type_id"
                  value={
                    transaction_type_id === null ? "" : transaction_type_id
                  }
                  onChange={(e) =>
                    onSimpleFieldChange(
                      "transaction_type_id",
                      e.target.value === "" ? null : e.target.value
                    )
                  }
                >
                  <option value="">Seleccione</option>
                  {transactionTypes().map((prop, key) => {
                    return (
                      <option key={key} value={prop.value}>
                        {prop.text}
                      </option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.taker}</strong>
              </label>
              <div>
                <span>{inspector}</span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.pledge}</strong>
              </label>
              <div>
                <span>
                  <Select
                    className="form-control"
                    name="pledge"
                    onChange={(e) =>
                      onSimpleFieldChange(
                        "pledge",
                        e.target.value === constants.dropdownDefault
                          ? ""
                          : e.target.value?.toUpperCase()
                      )
                    }
                    value={pledge?.toLowerCase() ?? constants.dropdownDefault}
                    // disabled={disabledByRol}
                  >
                    <option
                      value={constants.dropdownDefault}
                      className="capitalize"
                    >
                      Condición
                    </option>
                    {yesNoOptions.map((o) => (
                      <option
                        key={o.value}
                        value={o.value}
                        className="capitalize"
                      >
                        {o.text}
                      </option>
                    ))}
                  </Select>
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.received}</strong>
              </label>
              <div>
                <span>
                  <input
                    type="checkbox"
                    name="is_recived"
                    checked={is_recived}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        is_recived: e.target.checked,
                        purchase_file_status: e.target.checked
                          ? purchaseStatus.SIGNING_FORM_08.value
                          : read_only_purchase_file_status,
                      })
                    }
                    disabled={read_only_is_recived || disabledByRol}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container">
          <div className="row">
            {createdAtUpdate.includes(profile) ? (
              <div className="col">
                <label className="capitalize">
                  <strong>Recepción</strong>
                </label>
                <div>
                  <span>
                    <DatePicker
                      selected={createdAt === null ? null : new Date(createdAt)}
                      onChange={(date) =>
                        onSimpleFieldChange("createdAt", date)
                      }
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                    />
                  </span>
                </div>
              </div>
            ) : null}
            {/* <div className="col">
              <label className="capitalize">
                <strong>{constants.purchasePrice}</strong>
              </label>
              <div>
                <span>
                  <Input
                    type="text"
                    className="form-control"
                    name="vehicle_price"
                    placeholder="solo números"
                    autoComplete="false"
                    value={vehicle_price}
                    onChange={(e) =>
                      onSimpleFieldChange("vehicle_price", e.target.value)
                    }
                  />
                </span>
              </div>
            </div> */}
            {/* <div className="col">
              <label className="capitalize">
                <strong>{constants.lossRequest}</strong>
              </label>
              <div>
                <span>
                  <Input
                    type="text"
                    className="form-control"
                    name="loss_request"
                    placeholder={constants.lossRequest}
                    autoComplete="false"
                    onChange={(e) =>
                      onSimpleFieldChange("loss_request", e.target.value)
                    }
                    value={loss_request}
                  />
                </span>
              </div>
            </div> */}
            {sdp ? (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.finalPaymentDate}</strong>
                </label>
                <div>
                  <span>
                    <DatePicker
                      selected={
                        final_payment_date === null
                          ? null
                          : new Date(final_payment_date)
                      }
                      onChange={(date) =>
                        onSimpleFieldChange("final_payment_date", date)
                      }
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                    />
                  </span>
                </div>
              </div>
            ) : null}
            <div className="col">
              <label className="capitalize">
                <strong>{constants.purchaseCanceledStatus}</strong>
              </label>
              <div>
                <Dropdown
                  className="form-control"
                  name="canceled_status_id"
                  placeholder={constants.purchaseCanceledStatus}
                  onSelect={(e) => {
                    onSimpleFieldChange(
                      "canceled_status_id",
                      e.target.value === constants.dropdownDefault
                        ? null
                        : e.target.value
                    );
                  }}
                  selected={canceled_status_id}
                  options={canceledStatus}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.receptionPlace}</strong>
              </label>
              <div>
                <span>
                  <Dropdown
                    className="form-control"
                    name="reception_place_id"
                    placeholder={constants.receptionPlace}
                    onSelect={(e) => {
                      onSimpleFieldChange(
                        "reception_place_id",
                        e.target.value === constants.dropdownDefault
                          ? null
                          : e.target.value
                      );
                    }}
                    selected={reception_place_id}
                    options={placesOptions}
                  />
                </span>
              </div>
            </div>
            <div className="col-6">
              <label className="capitalize">
                <strong>Registro de Radicación</strong>
              </label>
              <Multiselect
                name="issuer_entity_id"
                options={issuerEntitiesAll()}
                selectedValues={selectedIssuer}
                onSelect={onSelect}
                onRemove={onRemove}
                displayValue="text"
                placeholder=""
                closeIcon="cancel"
                selectionLimit="1"
                showCheckbox={false}
                singleSelect={false}
                selectedValueDecorator={(v) => v.substr(0, 40)}
              />
            </div>
            {/* <div className="col-6">
              <label className="capitalize">
                <strong>Registro donde se Presenta</strong>
              </label>
              <Multiselect
                name="issuer_entity_id"
                options={issuerEntitiesAll()}
                selectedValues={selectedIssuerTentative}
                onSelect={onSelectTentative}
                onRemove={onRemoveTentative}
                displayValue="text"
                placeholder=""
                closeIcon="cancel"
                selectionLimit="1"
                showCheckbox={false}
                singleSelect={false}
                selectedValueDecorator={(v) => v.substr(0, 40)}
              />
            </div> */}
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col">
              <label>
                <strong>Formulario 08</strong>
              </label>
              <div>
                {form_08.current_status
                  ? status08Badge(form_08.current_status)
                  : badgeGeneric("danger", "No encontrado")}
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.notaryOffice}</strong>
              </label>
              <div>
                <span>
                  {read_only_form_08.purchase_file_form.notary_office}
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.received}</strong>
              </label>
              <div>
                <span>
                  <input
                    type="checkbox"
                    name="form_08_is_recived"
                    defaultChecked={form_08.has_form_08}
                    onChange={(e) => {
                      onComplexFieldChange(
                        "form_08",
                        "has_form_08",
                        e.target.checked
                      );
                    }}
                    disabled={
                      form_08.current_status !== status08.DELIVERED.value ||
                      disabledByRol
                    }
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </Form>
      <hr />
    </>
  );
};

export default Summary;
