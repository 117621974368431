import React, { useState, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotaryService from "../../../services/notary.service";
import AuthService from "../../../services/auth.service";
import List from "../../../components/Table/List.js";
import Filters from "../../../components/Filters";
import ButtonIcon from "../../../components/ButtonIcon";
import ConfirmationAlert from "../../../components/ConfirmationAlert/confirmationAlert";
import Content from "../../../components/Modal/NotaryOffice/content";
import Modal from "../../../layouts/ModalDisplay";
import { constants } from "../../../variables/constants";
import {
  useConfirmationAlert,
  useFilters,
  useGrid,
  useToast,
} from "../../../hooks";
import { updateLocalStorageCache } from "../../../utils/Service";
import { badgeGenericBoolean } from "../../../utils/Badges";
import { headSort } from "./constants/constants_columns";
import { filterFields } from "./constants/constants_filter";

const NotaryOfficesList = (props) => {
  const [show, setShow] = useState(false);
  const [dataModal, setDataModal] = useState(null);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const navigate = useNavigate();
  const addToast = useToast();
  const {
    confirmationModal,
    handleConfirmationModal,
    handleCloseConfirmationModal,
  } = useConfirmationAlert();

  const {
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
  } = useGrid(
    {
      model: null,
      field: "id",
      type: "ASC",
    },
    20
  );

  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);

  const sortColumns = (body) =>
    body.map((e) => ({
      id: e.id,
      name: e.name,
      address: e.address_complete,
      city: e.city_province,
      active: badgeGenericBoolean(e.active),
      ranking: e.ranking,
      _active: e.active,
    }));

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("ESCRIBANIAS");
    setCurrentPermissions(permissions);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters: filters,
    };
    NotaryService.list(params).then((response) => {
      if (response.data.success) {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        handleBody(sortColumns(response.data.response));
      }
    });
  }, [currentPage, currentSort, filters, refresh]);

  const handleToggleActive = (id, active) => {
    NotaryService.toggleActive(id, active)
      .then(() => {
        addToast({
          variant: "success",
          header: "Escribanías",
          body: "Se actualizó la escribanía correctamente",
        });
        refreshGrid();
        handleCloseConfirmationModal();
        updateLocalStorageCache();
      })
      .catch((error) => {
        addToast({
          variant: "danger",
          header: "Escribanías",
          body: "Ocurrió un error al actualizar la escribanía",
        });
        console.error(error);
      });
  };

  const handleShowModal = (id, e) => {
    e.preventDefault();
    setDataModal({ id });
    setShow(true);
  };

  const handleCloseModal = () => setShow(false);

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <ButtonIcon
            onClick={() => navigate(`/admin/notary-offices/${props.id}/edit`)}
            name="file-alt"
            title="Editar"
          />
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasUpdate ? (
          <ButtonIcon
            onClick={(e) => handleShowModal(props.id, e)}
            name={"users-cog"}
            color={"warning"}
            title={"Asociar Usuarios"}
          />
        ) : null}

        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <ButtonIcon
            onClick={() =>
              handleConfirmationModal(
                "",
                () => handleToggleActive(props.id, !props._active),
                "¿Desea modificar el estado de la escribanía?",
                "Al confirmar, se actualizará el estado del escribanía."
              )
            }
            name={props._active ? "user-xmark" : "user-check"}
            color={props._active ? "danger" : "success"}
            title={props._active ? "Desactivar" : "Activar"}
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className="content-filters ps-3 mb-4">
        <Button
          href="/admin/notary-offices/create"
          className="btn-fill btn btn-primary capitalize"
        >
          Nueva {constants.notaryOffice}
        </Button>
      </div>
      <Container fluid>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
        <Modal
          content={<Content data={dataModal} handleClose={handleCloseModal} />}
          showModal={show}
          handleShow={handleShowModal}
          handleClose={handleCloseModal}
          headerTitle={"Asociar Usuarios"}
        />
      </Container>
    </div>
  );
};

export default NotaryOfficesList;
