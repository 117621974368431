import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import { useDebounce } from "use-debounce";
import { Multiselect } from "multiselect-react-dropdown";

import inquiryService from "../../../services/purchase/inquiry.service";
import managementService from "../../../services/management.service";
import TransactionTypeService from "../../../services/transactionType.service";
import { Comments } from "../../Comments/index";
import { InputField, SelectField } from "../../../components/Form";
import { useToast } from "../../../hooks";
import { plateNumberBadge, statusBadge } from "../../../utils/Badges";
import { purchaseComment } from "../../../utils/Comments";
import { issuerEntitiesAll } from "../../../utils/Service";
import { constants, purchaseStatus } from "../../../variables/constants";

const InquiryEdit = (props) => {
  const {
    data: {
      vehicle_id,
      plate_number,
      transaction_type_id,
      comments,
      issuer_entity,
    },
    handleClose,
    refresh,
  } = props;

  const initialFormState = {
    vehicle_id,
    plate_number: "",
    original_plate_number: plate_number,
    transaction_type_id,
    comment: null,
    comment_reason_id: null,
    issuer_entity_id: null,
  };

  const [fields, setFields] = useState(initialFormState);
  const [reasons, setReasons] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const addToast = useToast();
  const newPlateNumber = useRef();
  const formRef = useRef();
  const [value_plate_number] = useDebounce(fields.plate_number, 600);

  useEffect(() => {
    if (!!value_plate_number) {
      formRef.current.hideError(newPlateNumber.current);
      inquiryService
        .getByPlateNumber(value_plate_number)
        .then((response) => {
          if (response.data.success) {
            // Si no hay registros, fuerzo el error para reusar el catch
            if (!response.data.totalItems) {
              throw new Error();
            }
            formRef.current.showError(
              newPlateNumber.current,
              <div>
                <div className="alert alert-warning" role="alert">
                  Se encontraron {response.data.totalItems} Dominios, mostrando{" "}
                  {response.data.count}.
                </div>
                {response.data.response.map((item) => (
                  <div className="mb-1">
                    {plateNumberBadge(item.plate_number)}
                    {statusBadge(item.status)}
                  </div>
                ))}
              </div>
            );
          }
        })
        .catch((error) => {
          formRef.current.showError(
            newPlateNumber.current,
            <div className="alert alert-success" role="alert">
              El Dominio no existe
            </div>
          );
        });
    }
    if (value_plate_number.length === 0) {
      formRef.current.hideError(newPlateNumber.current);
    }

    //eslint-disable-next-line
  }, [value_plate_number]);

  useEffect(() => {
    if (issuer_entity) {
      const allIssuers = issuerEntitiesAll();
      const issuer = allIssuers?.find((i) => i.text === issuer_entity);
      setSelectedIssuer([
        {
          value: issuer?.value || null,
          text: issuer?.text || null,
        },
      ]);
    }

    const getReasons = async () => {
      try {
        const response = await managementService.getCommentReasons();
        if (response.status === 200) {
          setReasons(response.data.response);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const getTransactionTypes = () => {
      TransactionTypeService.getAll().then((response) => {
        if (response.data.response.length > 0) {
          setTransactionTypes(response.data.response);
        }
      });
    };

    onSimpleFieldChange("comments", purchaseComment(comments));
    getReasons();
    getTransactionTypes();
    //eslint-disable-next-line
  }, []);

  const submit = async () => {
    const response = await inquiryService.update(vehicle_id, fields);
    if (response.status === 200) {
      addToast({
        variant: "success",
        header: "Toma - Consulta",
        body: "Se actualizó la información correctamente",
      });
      handleClose();
      refresh();
    } else {
      addToast({
        variant: "danger",
        header: "Toma - Consulta",
        body: "Se ha producido un error durante la actualización, intente nuevamente.",
      });
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const onSelect = (selectedList, selectedItem) => {
    onSimpleFieldChange("issuer_entity_id", selectedItem.value);
    setSelectedIssuer([selectedItem]);
  };

  const onRemove = () => {
    onSimpleFieldChange("issuer_entity_id", null);
    setSelectedIssuer(null);
  };

  return (
    <div className="modalChecklist">
      <Form ref={formRef}>
        <div className="container">
          <div className="row">
            <InputField
              label={"Stock ID"}
              name={"vehicle_id"}
              value={fields.vehicle_id}
              disabled
            />
            <InputField
              label={"Dominio Actual"}
              name={"original_plate_number"}
              value={fields.original_plate_number}
              disabled
            />
            <InputField
              label={"Nuevo Dominio"}
              name={"plate_number"}
              onChange={(e) =>
                onSimpleFieldChange("plate_number", e.target.value)
              }
              value={fields.plate_number}
              refs={newPlateNumber}
            />
          </div>
          <hr />
          <div className="row">
            <div className="col-6">
              <SelectField
                label={constants.transactionType}
                name={"type_id"}
                value={fields.transaction_type_id}
                options={transactionTypes}
                onChange={(e) => {
                  onSimpleFieldChange(
                    "transaction_type_id",
                    e.target.value === "" ? null : e.target.value
                  );
                }}
              />
            </div>
            <div className="col-6">
              <div className="form-group">
                <label className="capitalize">
                  <strong>Registro De Radicación</strong>
                </label>
                <Multiselect
                  name="issuer_entity_id"
                  options={issuerEntitiesAll()}
                  selectedValues={selectedIssuer}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="text"
                  placeholder=""
                  closeIcon="cancel"
                  selectionLimit="1"
                  showCheckbox={false}
                  singleSelect={false}
                  style={{
                    chips: { background: "#007bff" },
                    searchBox: {
                      color: "#FFFFFF",
                      border: "1px solid #ced4da",
                      borderRadius: "0.25rem",
                      padding: "0.375rem 0.75rem",
                      height: "40px",
                      minHeight: "38px",
                      overflow: "hidden",
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
          <Comments
            reasons={reasons}
            comments={fields.comments}
            statusList={purchaseStatus}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
          />
        </div>
        <br />
        <div className="modal-footer">
          <div>
            <Button
              className="btn-fill pull-right btn btn-primary btn-sm"
              onClick={() => submit(false)}
            >
              Actualizar
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InquiryEdit;
